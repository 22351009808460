.image-wrapper {
  clip-path: content-box;
  -webkit-clip-path: content-box;
  @media only screen and (min-width: 960px) {
    &:hover {
      .gamecard-overlay {
        opacity: 1;
        z-index: 20;
      }
      img {
        @apply transform-gpu;
      }
    }
  }
  @apply overflow-hidden rounded-2xl bg-bgr-default;
}
